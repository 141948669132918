import React from "react"
import styled, { css } from "styled-components"
import Link from "@src/components/core-link"
import PropTypes from "prop-types"

const focusedBorder = css`
  border: 3px solid ${props => props.theme.primary};
`
const ClickableTile = styled.div`
  position: relative;

  & > a.trigger {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    // Reference for hiding text: https://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;

    ${props => (props.focused ? focusedBorder : undefined)}
    &:hover {
      ${focusedBorder}
    }
  }
`

const ClickableTileWrapper = ({
  className,
  children,
  to,
  href,
  onClick,
  focused,
  onHover,
  title,
  external,
}) => {
  const linkProps = {}
  if (onClick) {
    linkProps.onClick = onClick
  } else if (to) {
    linkProps.to = to
  } else if (href) {
    linkProps.href = href
  }
  if (external) {
    linkProps.external = external
  }
  const handleMouseEnter = () => {
    if (onHover) {
      onHover()
    }
  }
  return (
    <ClickableTile
      className={className}
      focused={focused}
      onMouseEnter={() => handleMouseEnter()}
    >
      <Link className="trigger" {...linkProps} title={title}>
        {title}
      </Link>
      {children}
    </ClickableTile>
  )
}

ClickableTileWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  focused: PropTypes.bool,
  onHover: PropTypes.func,
  title: PropTypes.string.isRequired,
  external: PropTypes.bool,
}
export default ClickableTileWrapper
