import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import ImageWrapper from "@src/components/core-image-wrapper"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ClickableTileWrapper from "@src/components/core-clickable-tile-wrapper"
import FormatDate from "@src/components/core-value-date"

const Clickable = styled(ClickableTileWrapper)`
  position: relative;
  margin: 0;
  border-radius: 4px;
  text-decoration: none;
  flex: 0 0 168px;
  max-width: calc(50% - 6px);
  overflow: hidden;
  color: ${props => props.theme.black};
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.08);
  @media only screen and (${props => props.theme.screen.small.min}) {
    flex: 0 0 312px;
    border-radius: 8px;
  }
`
const ImageWrapperSize = styled(ImageWrapper)`
  height: 80px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 150px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  padding: 8px 6px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: calc(100% - 150px);
    padding: 15px 10px;
  }
`
const Title = styled.h4`
  // Reference: https://css-tricks.com/almanac/properties/l/line-clamp/
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 0.875rem;

  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 35px;
  }
`
const Details = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-end;
  color: ${props => props.theme.lightGray};
  font-size: 0.6875rem;
  font-weight: 500;

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

const PostTile = ({ title, link, image, date, author }) => {
  const postDetails = []
  if (author) {
    postDetails.push(<div key={`${title}-${author}`}>By {author}</div>)
  }
  if (date) {
    postDetails.push(<FormatDate key={`${title}-${date}`} date={date} />)
  }
  return (
    <Clickable to={link} title={`Open details for ${title}`}>
      <Image placeholder wrapper={ImageWrapperSize} {...image} />
      <Content>
        {title && <Title>{title}</Title>}
        {postDetails.length ? <Details>{postDetails}</Details> : null}
      </Content>
    </Clickable>
  )
}
PostTile.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({ ...ImagePropTypes }),
  date: PropTypes.number,
  author: PropTypes.string,
}
export default PostTile

export const PostTileQueryPropTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  images: PropTypes.shape({
    featureTile: PropTypes.shape({ ...ImagePropTypes }),
  }),
  date: PropTypes.string,
  author: PropTypes.shape({
    node: PropTypes.shape({ name: PropTypes.string }),
  }),
}
export const PostQueries = graphql`
  fragment TenAdventuresGQL_PostDetailsQuery on TenAdventuresGQL_Post {
    uri
    title
    images {
      featureTile {
        ...TenAdventuresGQL_ImageSizesQuery
      }
    }
    date: dateGmt
    author {
      node {
        name
      }
    }
  }
  fragment WpPostDetailsQuery on WpPost {
    uri
    title
    images {
      featureTile {
        ...WpImageSizesQuery
      }
    }
    date: dateGmt
    author {
      node {
        name
      }
    }
  }
`
