import React from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { paginateTitle, generateMeta, paginateUrl } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import PostTiles from "@src/components/content-post-tiles"
import { PostTileQueryPropTypes } from "@src/components/content-post-tile"
import NavPaginate from "@src/components/nav-content-paginate"
import { ImagePropTypes } from "@src/components/core-image"

const PageTemplate = ({
  pageContext: { basePath, pageNumber, pageCount, data: pageContextData },
}) => {
  const {
    page: {
      title,
      images: { header },
      seo: pageSeo,
    },
    posts: { nodes: posts },
  } = pageContextData
  GTM.dataLayerPushPageInfo({
    template: "blog-landing",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={paginateTitle(pageSeo.title, pageNumber, pageCount)}
        meta={generateMeta(
          basePath,
          { ...pageSeo, metaRobotsNoindex: "index" },
          { pageNumber, pageCount }
        )}
      />
      <HeaderSimple spacer="shadow" image={header}>
        <h1>{title}</h1>
      </HeaderSimple>
      <ResponsiveSection variant="tiles">
        <PostTiles content={posts} />
        <NavPaginate
          current={pageNumber}
          total={pageCount}
          getLinkProps={pageNumber => ({
            to: paginateUrl(basePath, pageNumber),
          })}
        />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    data: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      posts: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            ...PostTileQueryPropTypes,
          })
        ),
      }),
    }),
  }),
}

export default PageTemplate
