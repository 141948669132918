import React from "react"
import PropTypes from "prop-types"
import Button from "@src/components/core-button"
import { ImagePropTypes } from "@src/components/core-image"
import PostTile from "@src/components/content-post-tile"
import styled from "styled-components"

const PostTilesContainer = styled.div`
  // Currently the same styling as core-featured-tiles
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -3px;
  & > * {
    margin: 3px;
  }

  @media only screen and (${props => props.theme.screen.tablet.min}) {
    width: 375px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 694px;
    margin: -5px;
    & > * {
      margin: 5px;
    }
  }

  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: auto;
  }
`

const PostTiles = ({ viewMore, content, title }) => {
  const postTiles =
    content &&
    content?.map(post => (
      <PostTile
        key={post.title}
        title={post.title}
        link={post.uri}
        image={post.images?.featureTile}
        author={post.author?.node?.name}
        excerpt={post.excerpt}
        date={post.date && Date.parse(`${post.date}Z`)}
      />
    ))
  const actions = viewMore
    ? [
        <Button to={viewMore} key={`view-stories`}>
          View Stories
        </Button>,
      ]
    : undefined
  return (
    <>
      {title && <h2>{title}</h2>}
      <PostTilesContainer>{postTiles}</PostTilesContainer>
      <div className="actions">{actions}</div>
    </>
  )
}

PostTiles.propTypes = {
  title: PropTypes.string,
  viewMore: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
      images: PropTypes.shape({
        featureTile: PropTypes.shape({ ...ImagePropTypes }),
      }),
      excerpt: PropTypes.string,
      date: PropTypes.string,
      author: PropTypes.shape({
        node: PropTypes.shape({ name: PropTypes.string }),
      }),
    })
  ),
}
export default PostTiles
